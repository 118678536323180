type Body = Record<string, string | boolean | number>;
type UrlParams = Record<string, string>;

const csrfToken =
  document.querySelector("[name='csrf-token']")?.getAttribute("content") || "";

if (!csrfToken) {
  throw new Error(`no CSRF token found`);
}

async function fetchWithBody(method: string, url: string, body: Body) {
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);
  return response.json();
}

async function fetchWithUrlParams(
  method: string,
  url: string,
  params: UrlParams,
) {
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
  };

  const parameters = new URLSearchParams(params);
  url = url + (parameters.toString() ? `?${parameters.toString()}` : "");
  const response = await fetch(url, requestOptions);
  return response.json();
}

export async function postRequest(url: string, body: Body) {
  return fetchWithBody("POST", url, body);
}

export async function putRequest(url: string, body: Body) {
  return fetchWithBody("PUT", url, body);
}

export async function deleteRequest(url: string, params: UrlParams = {}) {
  return fetchWithUrlParams("DELETE", url, params);
}

export async function getRequest(url: string, params: UrlParams = {}) {
  return fetchWithUrlParams("GET", url, params);
}
